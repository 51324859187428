<template>
    <v-container fluid class="grey lighten-5 my-0 pa-0">
        <div class="text-center pt-10 pb-2">
            <v-btn :disabled="loading || showPreview" @click="getDocument">Reload</v-btn>
            <v-btn :disabled="loading || showPreview || !currentDoc" color="primary" class="ml-2" @click="previewDocument">Preview</v-btn>
        </div>

        <v-dialog class="test-center" v-model="loading" width="200">
            <div class="text-center white pa-10">
				<span>Loading Document...</span><br>
				<v-progress-circular indeterminate size="30" class="mt-2" />
            </div>
        </v-dialog>

        <v-dialog v-model="showPreview" :fullscreen="$vuetify.breakpoint.xs" width="90%" max-width="800px">
            <v-card style="position:relative;min-height:10rem">
				<v-btn fab left absolute color="primary" @click="downloadDocument">
					<v-icon>fas fa-download</v-icon>
				</v-btn>
				<v-btn fab right absolute color="red" @click="closePreview">
					<v-icon>fas fa-times-circle</v-icon>
				</v-btn> 
                <PdfPreview :value="documentUrl" />
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import QuilityAPI from '@/store/API/QuilityAPI.js'
import PdfPreview from '@/CMS/editor/components/PdfPreview.vue'

export default {
    components: {
        PdfPreview,
    },
    data () {
        return {
            loading: false,
            showPreview: false,
            currentDoc: null,
			params: {
				agentCode: '',
				leadCode: ''
			},
        }
    },
    mounted () {
		this.getDocument()
    },
	computed: {
		documentUrl () {
			if (!this.currentDoc) { return '' }
			return this.currentDoc
		},
	},
	watch: {
		'$route.path' () {
			if (this.$route.name !== 'LeadsheetsView') { return }
			this.getDocument()
		},
	},
    methods: {
        previewDocument () {
			this.showPreview = true
        },
        closePreview () {
			this.showPreview = false
        },
        async getDocument () {
            this.loading = true
			this.currentDoc = null
			this.params = { agentCode: '', leadCode: '' }
            const { agentCode, leadCode } = this.$route.params
			const response = await QuilityAPI.getLeadsheets(agentCode, leadCode)
			if (response.error) {
				this.showError('Could not load leadsheets', response.msg)
			} else {
				this.currentDoc = response
				this.params = { agentCode, leadCode }
				this.previewDocument()
			}
			this.loading = false
        },
        downloadDocument () {
			if (!this.currentDoc) { return }
			const { agentCode, leadCode } = this.params
			const anchor = document.createElement('a')
			anchor.download = `${agentCode}-${leadCode}.pdf`
			anchor.href = this.currentDoc
			anchor.click()
        },
    },
}
</script>
